import { defineStore } from "pinia";
export const useUserStore = defineStore("user", () => {
  const countryCode = ref();
  const loadCountryCode = async () => {
    if (!process.server) {
      const response: { country_code: string } = await $fetch(
        "https://ipapi.co/json",
      );
      countryCode.value = response?.country_code?.toUpperCase();
    }
  };

  loadCountryCode();

  return {
    countryCode,
  };
});

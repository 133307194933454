import { defineStore } from "pinia";
import { TDictionaryAll } from "@/types/api/TDictionaryAll";
import config from "@/config/env";

export type TDictionaryStoreData = TDictionaryAll;

export type DictionaryState = {
  error: null | unknown;
  pending: boolean;
  isReady: boolean;
  dictionary: TDictionaryStoreData | null;
};

export const useDictionaryStore = defineStore("dictionary", {
  state: (): DictionaryState => ({
    error: null,
    pending: false,
    dictionary: null,
    isReady: false,
  }),

  getters: {
    blockedCountriesCodes: (state): Set<string> => {
      const countries = new Set<string>();
      state.dictionary?.countries.forEach((country) => {
        if (country.block) countries.add(country.iso2);
      });
      return countries;
    },
  },

  actions: {
    async fetchDictionary() {
      this.pending = true;
      this.error = null;
      try {
        this.dictionary = await $fetch(config.apiUrl + "/dictionary/all");
        this.isReady = true;
      } catch (er) {
        this.isReady = false;
        this.error = er;
      } finally {
        this.pending = false;
      }
    },
  },
});
